@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.info-box{
  border: 1px solid #c3c3c3;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.info-box ul{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.light{
  font-weight: 400;
}

/* GLOBAL */
:root {
  --gray: #f4f4f4;
  --darker-gray: #e6e6e6;
  --turqoise: #BEE0E6;
  --darker-turqoise: #A2D4DA;
  --borders: #808080;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6, p{
  margin: 0;
  padding: 0;
}

ul, ol{
  margin-top: 0;
  margin-bottom: 0;
}

.no-padding{
  padding: 0 !important;
}

.padding-5-right{
  padding-right: 5px;
}

.padding-5-left{
  padding-left: 5px;
}

.padding-10-right{
  padding-right: 10px;
}

.padding-10-left{
  padding-left: 10px;
}

.padding-5-top{
  padding-top: 5px;
}

.padding-5-bottom{
  padding-bottom: 5px;
}

.padding-10-top{
  padding-top: 10px;
}

.padding-10-bottom{
  padding-bottom: 10px;
}

.padding-20-top{
  padding-top: 20px;
}

.padding-20-bottom{
  padding-bottom: 20px;
}

.padding-30-top{
  padding-top: 30px;
}

.padding-30-bottom{
  padding-bottom: 30px;
}

.padding-40-top{
  padding-top: 40px;
}

.padding-40-bottom{
  padding-bottom: 40px;
}

.github-picker > div:nth-child(2),
.github-picker > div:nth-child(1) {
  display: none;
}

.project-title{
  border-radius: 10px;
}

.width-10{
  width: 10%;
}

.width-20{
  width: 20%;
}

.width-25{
  width: 25%;
}

.width-30{
  width: 30%;
}

.width-40{
  width: 40%;
}

.width-45{
  width: 45%;
}

.width-50{
  width: 50% !important;
}

.width-60{
  width: 60%;
}

.width-70{
  width: 70%;
}

.width-75{
  width: 75%;
}

.width-80{
  width: 80%;
}

.width-90{
  width: 90%;
}

.width-100{
  width: 100%;
}

.flex{
  display: flex;
}

.flex.space-between{
  justify-content: space-between;
}

.flex.column{
  flex-direction: column;
}

.flex.column.center-h{
  align-items: center;
}

.flex.column.center-v{
  justify-content: center;
}

.flex.gap-10{
  gap: 10px;
}

.flex.gap-20{
  gap: 20px;
}

.flex.gap-30{
  gap: 30px;
}

.flex.gap-40{
  gap: 40px;
}

.flex.center-h{
  justify-content: center;
}

.flex.center-v{
  align-items: center;
}


.button-basic{
  padding: 10px 20px;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
}

.button-basic:hover{
  transform: scale(1.05);
  cursor: pointer;
}


.button{
  padding: 10px 20px;
  border: 1px solid #000;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover{
  background-color: #3c3c3c;
  color: white;
}

.container{
  width: 1100px;
  margin: 0 auto;
}

.section{
  padding: 100px 0 0 0;
}

a{
  color: black;
  text-decoration: none;
}

a:hover{
  cursor: pointer;
}

a:hover{
  cursor: pointer;
}


h1 {
  text-transform: uppercase;
  font-size: 4.5rem;
  line-height: 4.5rem; /* 1.2 * 5rem */
  font-weight: 400;
}

h2{
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 600;
}

h3 {
  font-size: 2.2rem;
  line-height: 2.2rem; /* 1.2 * 2.2rem */
  font-weight: 700;
}

h4{
  font-size: 1.8rem;
  line-height: 1.8rem; /* 1.2 * 1.8rem */
  font-weight: 700;
}

h5{
  font-size: 1.3rem;
  line-height: 1.3rem; /* 1.2 * 1.42rem */
  font-weight: 700;
}

p.s{
  font-size: 0.8rem;
  line-height: 0.96rem; /* 1.2 * 0.8rem */
}

p.r{
  font-size: 1rem;
  line-height: 1.2rem; /* 1.2 * 1rem */
}

p.l{
  font-size: 1.2rem;
  line-height: 1.44rem; /* 1.2 * 1.2rem */
}

p.xl{
  font-size: 1.4rem;
  line-height: 1.68rem; /* 1.2 * 1.4rem */
}

.bold{
  font-weight: 700;
}

p.line-height-spaced{
  line-height: 2rem;

}

.plus{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  border-radius: 50%;
  border: 1px solid #3c3c3c;
  cursor: pointer;
}

.project-buttons{
  padding-top: 10px;
  display: flex;
  gap: 10px;
}

.project-buttons button{
  padding: 10px 20px;
  border: 1px solid #000;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;

}

.project-buttons button:hover{
  background-color: #3c3c3c;
  color: white;
}


.plus:hover{
  background: #3c3c3c;
  color: white;
}

.button-secondary{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--turqoise);
  height: 2.5rem;
  border: 1px solid var(--borders);
  border-radius: 2px;
  font: inherit;
  padding: 0 20px;
  overflow: hidden;
}

.button-secondary:hover{
  background: var(--darker-turqoise);
  cursor: pointer;
}


/* PUBLIC PERSON */
.public-person-gallery {
  width: 100%;
}

.public-person-gallery .ReactGridGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.public-person-gallery .ReactGridGallery img {
  width: 100%;
  height: auto;
}

.public-person{
  display: flex;
  flex-direction: column;
}

.public-person > div{
  width: 100%;
}

.person-content ul{
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.public-person-gallery{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.public-person-gallery img{
  width: 100%;
}

.public-main-image{
  width: 20%;
}
/* END PUBLIC PERSON */




/* HEADER */
.header{
  display: flex;
  height: 100px; /* Adjust as needed */
  align-items: center;
}

.logo{
  height: 90px; /* Adjust as needed */
  display: flex;
  align-items: center;
}

.logo img{
  height: 70px;
}

.navigation{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.nav-items{
  width: 60%;
  display: flex;
  justify-content: space-between;
}

/* PUBLIC HOME */
.home-page{
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-landing{
  background: var(--turqoise);
  display: flex;
  margin-bottom: 5%;
  border-radius: 5px;
}

.landing-image{
  width: calc(100% / 3);
}

.landing-image img{
  width: 100%;
}

.landing-info{
  box-sizing: border-box;
  padding: 20px;
}

.landing-info p{
  font-size: 1.2rem;
}

/* PROFILES */
.profiles-page{
  padding-bottom: 10%;
}

.profiles-controls{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2%;
}

.profiles-controls-buttons{
  display: flex;
  width: 15%;
  justify-content: space-between;
}

.profiles-controls-buttons button{
  line-height: 0;
  width: 20%;
}

.profiles{
  display: flex;
  flex-direction: column;
}

.profile-grid{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.profile-card{
  position: relative;
  background: var(--gray);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.profile-card:hover{
  background: var(--darker-gray);
}

.profile-card input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-card img{
  width: 100%;
  border-radius: 50%;
}

.profile-card img.inner-1.outer-1{
  box-shadow: 0 0 0 5px green,
  0 0 0 10px purple;
}

.profile-card img.inner-1.outer-2{
  box-shadow: 0 0 0 5px green,
  0 0 0 10px rgb(90, 115, 255);
}

.profile-card img.inner-1.outer-0{
  box-shadow: 0 0 0 5px green;
}


.profile-card img.inner-0.outer-1{
  box-shadow: 0 0 0 5px red,
  0 0 0 10px purple;
}

.profile-card img.inner-0.outer-2{
  box-shadow: 0 0 0 5px red,
  0 0 0 10px rgb(90, 115, 255);
}

.profile-card img.inner-0.outer-0{
  box-shadow: 0 0 0 5px red;
}

.delete-images{
  font: inherit;
}

/* 
.profile img.inner-0{
  box-shadow: 0 0 0 5px red;
}

.profile img.outer-1{
  box-shadow: 0 0 0 10px green;
} */

/* .profile-card img.main-0{
  box-shadow: 0 0 0 5px orange;
} */

/* 
.profile-card img.archived.has_video {
  box-shadow: 
    0 0 0 5px gray,
    0 0 0 10px purple;
}

.profile-card img.active.has_video {
  box-shadow: 
    0 0 0 5px green,
    0 0 0 10px purple;
}

.profile-card img.inactive.has_video {
  box-shadow: 
    0 0 0 5px red,
    0 0 0 10px purple;
} */

.profile-card-title{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-data{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.profile-page{
  margin-top: 5%;
}

/* PROJECTS */
.projects-page{
  padding-bottom: 10%;

}

.project{
  padding-bottom: 5%;
}


.project-card{
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--gray);
  padding: 20px;
  border-radius: 5px;
}

.project-card:hover{
  background: var(--darker-gray);
}

.project-title-card{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
}

.project-card p{
  padding-top: 20px;
  text-align: center;
}

.project-title-card h3{
  padding: 0;
}

.selected-people{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-profiles{
  display: flex;
  width: 100%;
}

.project-profiles{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.project-profile-card{
  position: relative;
  background: var(--gray);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.project-profile-card.public{
  padding: 0;
}

.card-icon:hover{
  cursor: pointer;
  color: var(--turqoise)
}

.card-icons{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px 0 15px;
}

.project-profile-grid{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.public-project-card-content{
  padding: 20px;
}

.public-project-card-content img{
  display: none;
}

.person-comment{
  box-sizing: border-box;
  resize: none;
  margin-top: 20px;
  min-height: 6rem;
  padding: 5px;
  width: 100%;
}

.comment-buttons{
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.comment-buttons button{
  width: 100%;

}

.project-profile-card-comment{
  display: flex;
  width: 100%;
  min-height: 4rem;
  line-height: 1.2rem;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  color: black;
  padding: 10px;
  overflow: auto;
  background: white;
  border: 1px solid var(--borders);
  font-size: 0.9rem;
}


.project-profile-card-comment textarea:focus-visible{
  border: 1.5px solid var(--turqoise);
  outline: none;
}



.project-comment-heading{
  font-weight: 400;
}


.main-comment textarea{
  margin-top: 20px;
  width: 50%;
  resize: none;
  min-height: 6rem;
  line-height: 1.2rem;
  overflow: auto;
}

.profile-card .delete-icon {
  position: absolute;
  top: 0;
  right: 10px;
}

.profile-card-title{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.project-profile-card img{
  width: 100%;
  border-radius: 50%;
}

.projects-grid{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.projects-list{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-people-controls{
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.dropdown-container{
  width: 100%;
}

.project-input{
  width: 100%;
  height: 40px;
  padding-left: 5px;
}

.project-people-button{
  padding: 0;
  font-size: 16px;
  line-height: 0;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: 1px solid #000;
  border-left: none;
  cursor: pointer;
}
/* 
.new-project-form{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-project-form .info{
  margin-top: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-project-form .info label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.new-project-form .info label input{
  padding-left: 10px;
}

.new-project-form .data-title{
  margin-top: 20px;
}

.new-project-form .data{
  margin-top: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-project-form .data label{
  display: flex;
  align-items: center;
}

.new-project-form .data label input{
  margin-right: 10px;
} */

/* CONFIRMATION WINDOW */
.confirmation-window{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.confirmation-box{
  background: #fff;
  padding: 20px;
  width: 250px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-buttons{
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.confirmation-buttons button{
  border: 1px solid #000;
  background: var(--darker-gray);
  width: 100px;
  padding: 10px 0;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.confirmation-buttons button:hover{
  background: rgb(212, 212, 212);
}

/* APPLY CHOICE PAGE */
/* .apply-choice{
  margin-top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.choice-cards{
  display: flex;
  justify-content: center;
  align-items: stretch; 
  gap: 40px;
}

.choice-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--turqoise);
  border-radius: 5px;
  width: 400px;
  flex: 1; 
}

.choice-card-link{
  display: flex;
  flex: 1;
}

.choice-card-info{
  padding: 20px;
}

.choice-card-list{
  padding-left: 20px;
}

.choice-card-list li{
  list-style: disc;
}

.choice-price{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--darker-turqoise);
  padding: 10px;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
} */

/* 
.input-form-container .input-thumbnail-pic{
  width: 30%;
  position: relative;
}

.input-form-container .input-thumbnail-pic img{
  width: 100%;
}

.input-delete-thumbnail{
  line-height: 0;
  position: absolute;
  top: 5px;
  right: 5px; 
  background: none;
  padding: none;
  border: none;
  color: red;
  cursor: pointer;
}

.choose-thumbnail-controls{
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10px;
} */

/* 
.profile-edit-title{
  display: flex;
  justify-content: space-between;
}

.profile-edit-title .profile-edit-title-text{
  display: flex;
  width: 50%;
  flex-direction: column;
}


.choose-thumbnail p{
  padding: 0;
  line-height: 2rem;
  display: inline-block;
}

.choose-thumbnail-button{
  width: 25%;
  height: 3rem;
  border: 1px solid #c6c6c6;
  padding: 0 20px;
  cursor: pointer;
}

.input-form.image input{
  padding: 0;
}

.input-tag {
  display: flex;
  justify-content: space-between;
  line-height: 0.8rem;
  font-size: 0.8rem;
  background-color: rgb(131, 131, 241);
  padding: 15px 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.input-tag-trash {
  line-height: 0;
  font-size: 0.8rem;
  margin-left: 10px;
}

.input-tag-trash:hover {
  line-height: 0;
  margin-left: 10px;
}

.input-form.statuss .checkbox-container{
  display: flex;
  font-size: 1rem;
  line-height: 0;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.input-form.statuss .checkbox-container label{
  display: flex;
  height: 1rem;
  line-height: 1rem;
}

.input-form.statuss .checkbox-container label input{
  margin: 0;
  padding: 0;
  height: 1rem;
  margin-right: 10px;
}

.input-form.piezimes input{
  width: 95%;
}

.input-form.piezimes .checkbox-container input{
  width: 20px;
  margin: 0 0 0 0;
}

.checkbox-container label{
  margin: 0 0 0 10px;
  line-height: 0;
}

.input-overlay-images{
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  gap: 20px;
}

.input-overlay-images img{
  height: 80%;
} */


/* .input-form-container.prasmes{
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.input-form-container p.input-form-p{
  width: 80%;
}

.input-form{
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-form select.current-occ{
  height: 2rem;
  padding: 0 10px;
  margin-top: 10px;
  width: 100%;
}

.input-form label{
  margin-right: 10px;
}

.input-form .form-group-label{
  margin-top: 10px;
}

.input-form .form-group {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 10px;
}

.input-form .form-group.gender {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin-top: 0px;
  gap: 10px;
}

.input-form .form-group.contact {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin-top: 10px;
  gap: 10px;
}

.form-group.contact .dropdown-container{
  width: 100% !important;
}

.form-group.contact .dropdown-container input{
  width: 80% !important;
}

.input-form .form-group.contact .code {
  width: 10%;
}

.input-form .form-group.contact .number {
  width: 40%;
}

.input-form .person_email {
  width: 80%;
}

.input-form .person_address {
  width: 80%;
}

.input-form .form-group.parent {
  align-items: center;
}

.input-form .form-group.parent .parent_name {
  width: 42%;
}

.input-form .form-group.parent .parent_phone_code {
  width: 8%;
}

.input-form .form-group.parent .parent_phone {
  width: 32%;
}

.gender-button{
  line-height: 2rem;
  width: 20%;
}

.gender-button.selected{
  line-height: 2rem;
  width: 20%;
  background-color: rgb(197, 207, 236);
}

.input-form .form-group input{
  padding: 0 10px;
  margin: 0;
}

.input-form .form-group.dob input{
  width: 80%;
}

.input-form .form-group.play-age input{
  width: 45%;
}

.input-form.ipasibas input {
  width: 95%;
}

.clear-button{
  line-height: 2rem;
  width: 20%;
}

.experience{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.experience label{
  padding-bottom: 10px;
}

.experience-textbox{
  height: 7rem;
  padding: 10px;
  resize: none;
}

.input-form .form-group.aktierspeja {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin-top: 0px;
  gap: 10px;
}

.aktierspeja-button{
  line-height: 2rem;
  width: 50%;
}

.aktierspeja-button.selected{
  line-height: 2rem;
  width: 50%;
  background-color: rgb(197, 207, 236);
} */



/* FILTER INPUT */
.filter-inputs-row{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding-bottom: 20px;
  align-items: flex-end;
}

.filter-input{
  display: flex;
  flex-direction: column;
}

.filter-input input,
.filter-input select{
  padding: 0 10px;
  line-height: 0;
  height: 2.5rem;
  box-sizing: border-box;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
}



.filter-input select{
  padding: 0 10px;
}

.double-filter{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.double-filter input{
  width: 50%;
}

.filter-controls{
  display: flex;
  justify-content: space-between;
}

.filter-controls button{
  width: 48%;
  padding: 5px 10px;
}



/* PERSON INFO */
.profile-info{
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  gap: 10px;
  padding-top: 5%;
  padding-bottom: 5%;
}

.profile-info-table{
  width: 50%;
  margin: auto;
}

.person-info-card{
  width: 50%;
}

.person-card-data {
  list-style:circle;
}

.person-card-data li{
  margin-left: 25px;
}

.profile-info-table tr {
  padding: 0;
}

.profile-info-table td {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
}

.profile-info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.profile-info-table tr:hover {
  background-color: #ddd;
}

.order-info-table td:first-child {
  font-weight: bold;
}

/* SINGLE PERSON */
/* .single-person{
  margin-top: 5%;
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.person-gallery{
  width: 60%;
}

.person-card{
  width: 60%;
  background: var(--gray);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}

.person-thumbnail img{
  width: 250px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
}

.person-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.person-info p{
  padding-bottom: 5px;
} */


/* SPINNER */
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}


.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
}

.vairak-info{
  margin-top: 10px;
  font-family: inherit;
  padding: 5px 10px;
}

.vairak-info:hover{
  opacity: 0.9;
}

.user-card {
 display: flex;
 align-items: center;
 justify-content: space-between;
 background-color: #fff;
 border-radius: 10px;
 padding: 40px;
 width: 40rem;
 position: relative;
 overflow: hidden;
 box-shadow: 0 2px 20px -5px rgba(0,0,0,0.5);
}

.user-card-img {
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;   
}

.user-card-img img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.user-card-info {
  width: 50%;
  text-align: left;
}

.user-card-info h2 {
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
}

.user-card-info p {
  font-size: 14px;
  margin-bottom: 2px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-content {
  position: relative;
}

.overlay-content img {
  width: 100%;
  max-height: 80vh; /* Adjust as needed */
  object-fit: contain;
}

.close-button,
.nav-button {
  position: absolute;
  font-size: 24px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}

.close-button-card:hover{
  color: red;
}

.close-button {
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}

.nav-button.prev-button {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.nav-button.next-button {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* PROJECT PAGE */
/* .project-page-controls{
  display: flex;
}

.project-page-btn{
  background-color: var(--turqoise);
  padding: 0 20px;
  margin-left: 5px;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
}

.project-page-btn:hover{
  background-color: var(--darker-turqoise);
  cursor: pointer;
}


.project-controls{
  width: 100%;
}

.project-controls input{
  padding: 5px 10px;
  width: 90%;
}

.project-controls button{
  padding: 5px 10px;
  width: 100%;
}

.project-title{
  padding-bottom: 20px;
}

.project-title h1{
  margin-bottom: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.project-title h1.public-project-title{
  padding: 0;
  margin: 0;
  font-size: 3.5rem;
}

.project-people-title{
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 1.5rem;
} */


/* LOGIN */
/* .login{
  padding-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card{
  padding: 2% 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d6d6d629;
}

.login-title{
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

.login-form{
  width: 80%;
}

.form-group.username input,
.form-group.password input {
  width: 95%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.login.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login.btn svg {
  margin-right: 8px;
}

.login.btn-reverse {
  background: #fff;
  color: #000;
}

.login.btn-block {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.login.btn:hover {
  transform: scale(0.98);
}

.image-example-overlay img{
  border: solid 4px white;
}


.input-label{
  margin-bottom: -8px;
  font-size: 0.8rem;
} */

.category-list {
  list-style-type: none;
  padding: 0;
  border: solid 1px #ddd;
  border-bottom: 0;
}

.category-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.category-list li:nth-child(even) {
  background-color: #f2f2f2;
}

.category-list li:hover {
  background-color: #ddd;
  cursor: pointer;
}

.category-link {
  text-decoration: none;
  color: inherit;
  display: block;
}


.value-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.classifier-edit-list {
  margin: 20px;
}

@media only screen and (min-width: 768px) {
  .user-card {
    flex-direction: row;
    align-items: flex-start;
  }   
  .user-card-img {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .user-card-info {
    text-align: left;
  }
}

@media (max-width: 767px){
  .wrapper{
     padding-top: 3%;
  }
  .user-card:before {
     width: 300%;
     height: 200px;
     transform: rotate(0);
  }
  .user-card-info h2 {
     margin-top: 25px;
     font-size: 35px;
  }
  .user-card-info p span {
     display: block;
     margin-bottom: 15px;
     font-size: 18px;
  }
}