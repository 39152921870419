.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
    position: relative;
}

.tag-input-container input{
    border: none;
}

.tag-input-container input:focus-visible{
    outline: none;
}

.tag {
  background-color: #e0e0e0;
  border-radius: 3px;
  padding: 3px 8px;
  margin: 2px;
  display: flex;
  align-items: center;
}

.tag-close {
  margin-left: 5px;
  cursor: pointer;
}

.delete-icon-tags:hover{
  color: red;
}

.options-list {
    position: absolute;
    z-index: 999;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
}

.options-list li {
  padding: 8px;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f0f0f0;
}


/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
}

html {
    font-size: 14px;
}

h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
}

ul, ol{
    margin-top: 0;
    margin-bottom: 0;
}

.no-padding{
    padding: 0 !important;
}

.padding-5-top{
    padding-top: 5px;
}

.padding-5-bottom{
    padding-bottom: 5px;
}

.padding-10-top{
    padding-top: 10px;
}

.padding-10-bottom{
    padding-bottom: 10px;
}

.padding-20-top{
    padding-top: 20px;
}

.padding-20-bottom{
    padding-bottom: 20px;
}

.padding-30-top{
    padding-top: 30px;
}

.padding-30-bottom{
    padding-bottom: 30px;
}

.padding-40-top{
    padding-top: 40px;
}

.padding-40-bottom{
    padding-bottom: 40px;
}

.width-10{
    width: 10%;
}

.width-20{
    width: 20%;
}

.width-25{
    width: 25%;
}

.width-30{
    width: 30%;
}

.width-40{
    width: 40%;
}

.width-45{
    width: 45%;
}

.width-50{
    width: 50%;
}

.width-60{
    width: 60%;
}

.width-70{
    width: 70%;
}

.width-75{
    width: 75%;
}

.width-80{
    width: 80%;
}

.width-90{
    width: 90%;
}

.width-100{
    width: 100%;
}

.flex{
    display: flex;
}

.flex.column{
    flex-direction: column;
}

.flex.gap-10{
    gap: 10px;
}

h2{
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 600;
}

.button-basic{
    padding: 10px 20px;
    border: 1px solid #c3c3c3;
    border-radius: 2px;
} */



/* INPUTS GENERAL */
.basic-input{
    height: 2.8rem;
    padding: 0 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.input-form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.input-form-container > *{
    width: 50%;
}

.input-form{
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
}

.input-form-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.input-form .form-input{
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.form-input input,
.form-input select{
    height: 2.8rem;
    padding: 0 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.form-input textarea{
    font: inherit;
    font-size: 1rem;
    padding: 5px;
    height: 8rem;
    overflow-y: scroll;
    box-sizing: border-box;
    resize: none;
}

.submit-button{
    padding: 10px 20px;
    border: 1px solid #c3c3c3;
    cursor: pointer;
    border-radius: 3px;
}

.submit-button.delete-images{
    background-color: red;
    color: white;
    margin-bottom: 10px;
}

.submit-button.submit-inputs{
    background-color: green;
    color: white;
    margin-top: 10px;
}

.input-form-container .input-thumbnail-pic{
    width: 30%;
    height: auto;
    position: relative;
}

.input-form-container .input-thumbnail-pic img{
    width: 100%;
}

.input-delete-thumbnail{
    line-height: 0;
    position: absolute;
    top: 5px;
    right: 5px; 
    background: none;
    padding: none;
    border: none;
    color: red;
    cursor: pointer;
  }


/* INPUT TAGS */
.input-tag-selection input,
.input-selection input{
    width: 100%;
}

.dropdown-container{
    position: relative;
}

.dropdown{
    position: absolute;
    z-index: 999;
    width: 100%;
}

.dropdown ul{
    background: #FFFFFF;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #c3c3c3;
    max-height: 16rem;
    overflow-y: scroll;
}

.dropdown ul li{
    padding: 5px 10px;
    border-bottom: 1px solid #c3c3c3;
}

.input-tag {
    display: flex;
    justify-content: space-between;
    line-height: 0.8rem;
    font-size: 0.8rem;
    background-color: rgb(131, 131, 241);
    padding: 15px 20px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.input-tag-trash {
    line-height: 0;
    font-size: 0.8rem;
    margin-left: 10px;
}

.input-tag-trash:hover {
    line-height: 0;
    margin-left: 10px;
    cursor: pointer;
    color: red;
}

.selected-inputs {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    margin-top: 5px;
}


/* INPUT OVERLAYS */
.input-overlay-images{
    z-index: 9999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    top: 0;
    left: 0;
    gap: 20px;
  }
  
  .input-overlay-images img{
    height: 80%;
  }

  .close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: red;
    font-size: 3rem;
    background: transparent;
    border: none;
    padding: 0;
  }

  .image-example-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
  }
  
  .image-example-overlay img{
    width: 20%;
  }
  
  .image-example-overlay p{
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    width: 40vw;
    min-height: 10vw;
    background: white;
  }
  
  .image-example-overlay .overlay-close-button {
    width: 40vw;
    position:relative;
    display: block;
    border: 1px solid #3c3c3c;
    margin-top: 20px;
    height: 40px;
    border-radius: 5px;
    line-height: 0;
    background: var(--turqoise);
    cursor: pointer;
  }
  
  .image-example-overlay .overlay-close-button:hover {
    background: var(--darker-turqoise);
  }
  
  .image-example-overlay img{
    border: solid 4px white;
  }



/* IMAGE INPUTS */
.multi-image-card{
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.multi-image-card p{
  font-weight: bold;
  color: #0086fe;
}

.multi-image-card button{
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background: #0086fe;
}

.multi-image-card .drag-area{
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #0086fe;
  color: #0086fe;
  background: #f4f3f9;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}

.multi-image-card .drag-area .visible{
  font-size: 18px;
}

.multi-image-card .select {
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.multi-image-card .select:hover{
  opacity: 0.6;
}

.multi-image-card .image-container{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: hidden;
  margin-top: 10px;
}

.multi-image-card .image-container .image{
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-top: 8px;
}

.multi-image-card .image-container .image img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.multi-image-card .image-container .image span{
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.multi-image-card input,
.multi-image-card .drag-area .on-drop,
.multi-image-card .drag-area.dragover .visible{
  display: none;
}

.delete {
  z-index: 999;
  color: #0086fe;
}


.thumbnail-pic {
  position: relative;
  display: inline-block;
}

.thumbnail-pic img {
  display: block;
  width: 100%;
  height: auto;
}

.thumbnail-pic button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  font-size: 2rem;
  padding: 0;
  line-height: 2rem;
  color: red;
  border: none;
  cursor: pointer;
}
